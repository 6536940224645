import { Aside } from '../../components/aside';
import { Footer } from '../../components/footer/infex';
import { Header } from '../../components/header';
import { Promo } from '../../components/promo';

export const HomePage = () => {
  return (
    <div className='__site_wrapper'>
      <Header />
      <Aside />
      <main className='main'>
        <Promo />
      </main>
      <Footer />
    </div>
  )
}
