import { Header } from '../../components/header';
import { Aside } from '../../components/aside';
import { Button } from '../../components/ui/button';

import './notFound.scss';

export const NotFoundPage = () => {
    return (
        <div className='__site_wrapper'>
            <Header />
            <Aside /> 
            <main className='main'>
                <section className="section notFound">
                    <div className="container">
                        <div className="section_inner">
                            <div className="notFound_icon">
                                <svg width="503" height="503" viewBox="0 0 503 503" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M251.308 0L252.133 12.3728C257.703 95.9558 356.638 136.936 419.678 81.7724L429.01 73.6065L420.844 82.9384C365.681 145.979 406.661 244.914 490.244 250.484L502.617 251.308L490.244 252.133C406.661 257.703 365.681 356.638 420.844 419.678L429.01 429.01L419.678 420.844C356.638 365.681 257.703 406.661 252.133 490.244L251.308 502.617L250.484 490.244C244.914 406.661 145.979 365.681 82.9384 420.844L73.6065 429.01L81.7724 419.678C136.936 356.638 95.9558 257.703 12.3729 252.133L0 251.308L12.3728 250.484C95.9558 244.914 136.936 145.979 81.7724 82.9384L73.6065 73.6065L82.9384 81.7724C145.979 136.936 244.914 95.9558 250.484 12.3729L251.308 0Z" fill="#E05327" />
                                    <path d="M202.418 252.489H214.823V267.294H202.418V285H186.713V267.294H152.201V252.489L178.91 209.974H202.418V252.489ZM187.213 252.489V224.279L169.407 252.489H187.213ZM250.613 286.2C234.808 286.2 222.804 274.296 222.804 258.791V236.283C222.804 220.778 234.808 208.773 250.613 208.773C266.419 208.773 278.323 220.678 278.323 236.283V258.791C278.323 274.296 266.319 286.2 250.613 286.2ZM250.613 271.095C257.115 271.095 262.117 265.993 262.117 259.291V235.783C262.117 229.08 257.115 223.979 250.613 223.979C244.011 223.979 239.109 229.08 239.109 235.783V259.291C239.109 265.993 244.011 271.095 250.613 271.095ZM335.961 252.489H348.365V267.294H335.961V285H320.255V267.294H285.743V252.489L312.453 209.974H335.961V252.489ZM320.756 252.489V224.279L302.949 252.489H320.756Z" fill="#AE3532" />
                                </svg>
                            </div>
                            <div className="notFound_box">
                                <h2 className="notFound__title">
                                    упс! ЧТО-ТО <br /> ПОШЛО НЕ&nbsp;ТАК
                                </h2>
                                <div className="notFound__actions">
                                    <Button className="__secondary __orange" link path="/">Вернуться на главную</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}
