import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { appStore } from '../../store/appStore';

import Star from '../../assets/img/star.png'

import './promo.scss';

export const Promo = observer(() => {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            appStore.changeBlock = true;
        }, 2000);
        setTimeout(() => {
            appStore.changeBlock = false;
            navigate('/info');
        }, 3000);
        // eslint-disable-next-line
    }, [])

    return (
        <section className={`section promo ${appStore.changeBlock ? '__hidden' : ''}`}>
            <div className="container">
                <div className="section_inner">
                    <div className="promo__star">
                        <img src={Star} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
})
