import { Header } from '../../components/header';
import { Aside } from '../../components/aside';
import { Footer } from '../../components/footer/infex';
import { Questions } from '../../components/questions';

export const QuestionsPage = () => {
    return (
        <div className="__site_wrapper __bg_2">
            <Header />
            <Aside />
            <main className='main'>
                <Questions />
            </main>
            <Footer />
        </div>
    )
}
