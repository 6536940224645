import { Link } from 'react-router-dom';

import { menuStore } from '../../store/menuStore';

import Logo from '../../assets/svg/logo_1.svg';
import Menu from '../../assets/svg/menu.svg';

import './header.scss';
 
export const Header = () => {
  return (
    <header className='header'>
        <div className="container">
            <div className="header_inner">
                <Link className="logo" to="https://achbd.media/" target='_blank'>
                    <img src={Logo} alt="" />
                </Link>
                <div className="burger" onClick={() => menuStore.toggleMenu()}>
                    <img src={Menu} alt="" />
                </div>
            </div>
        </div>
    </header>
  )
}
