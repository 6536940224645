import { makeAutoObservable } from "mobx";

class AppStore {
  isVisible = false;
  changeBlock = false;

  constructor() {
    makeAutoObservable(this);
  }

  show() {
    this.isVisible = !this.isVisible;

    setTimeout(() => {
        this.isVisible = !this.isVisible;
    }, 2000);
  }
}

export const appStore = new AppStore();
