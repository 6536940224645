import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { appStore } from '../../store/appStore';
import { infoStore } from '../../store/infoStore';
import { targetYM } from '../../helper';

import { Header } from '../../components/header';
import { Aside } from '../../components/aside';
import { Footer } from '../../components/footer/infex';

import './info.scss';

export const InfoPage = observer(() => {
  const [isHide, setIsHide] = useState(false);
  const navigate = useNavigate();

  const toggleClick = (id, index) => {
    if (index !== 3) {
      appStore.show();
    } else {
      setIsHide(true);
    }

    setTimeout(() => {

      if (index !== 3) {
        navigate(`/answer/${id}`);
      } else {
        navigate('/questions');
        setIsHide(false);
      }

    }, 2000);
  }

  useEffect(() => {
    infoStore.getData();
  }, [])

  return (
    <div className='__site_wrapper __bg_1'>
      <Header />
      <Aside />
      <main className='main'>
        <section className={`section info ${isHide ? '__hide' : ''}`}>
          <div className="container">
            <div className="section_inner">
              <div className="info__items">
                {infoStore.data.length > 0 &&
                  infoStore.data.map(({ name, id, reachGoal }, index) => (
                    <div className="info__item" data-id={id} key={id} onClick={() => {
                      toggleClick(id, index);
                      targetYM(reachGoal);
                    }}>
                      <div className="info__item_icons">
                        <div className="info__item_icon"></div>
                      </div>
                      <p className="info__item_text">
                        {name}
                      </p>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  )
})
