import { makeAutoObservable } from "mobx";

class MenuStore {
    isActive = false;

    constructor() {
        makeAutoObservable(this);
    }

    toggleMenu = () => {
        this.isActive = !this.isActive;

        if(this.isActive) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
    }
}

export const menuStore = new MenuStore();