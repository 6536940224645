import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { footerStore } from '../../store/footerStore';

import './footer.scss';

export const Footer = observer(() => {

  useEffect(() => {
    footerStore.getData();
  }, [])

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_inner">
          <Link className="footer__logo __left" to="https://achbd.media/" target='__blank'>
            <svg width="180" height="100" viewBox="0 0 180 100" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2201_3814)">
                <path d="M12.001 40.351H9.17591L8.76777 48.0817C8.75375 48.3605 8.63243 48.6232 8.429 48.8155C8.22557 49.0077 7.95562 49.1147 7.67503 49.1144H2.73842C2.587 49.1145 2.4372 49.0833 2.29854 49.0228C2.15988 48.9622 2.03537 48.8737 1.93293 48.7628C1.83049 48.6519 1.75236 48.521 1.70346 48.3784C1.65456 48.2358 1.63597 48.0847 1.64889 47.9346L5.54096 1.46748C5.56432 1.19525 5.68957 0.941662 5.89194 0.75693C6.09431 0.572198 6.35905 0.469791 6.63371 0.46998H15.141C15.4143 0.469719 15.6778 0.571342 15.8795 0.754812C16.0812 0.938283 16.2064 1.19028 16.2305 1.46109L20.4376 47.941C20.4509 48.0915 20.4327 48.2431 20.3841 48.3862C20.3354 48.5294 20.2574 48.6608 20.1549 48.7724C20.0525 48.8839 19.9279 48.973 19.789 49.0341C19.6501 49.0952 19.4999 49.1269 19.348 49.1272H13.4987C13.2181 49.1275 12.9481 49.0205 12.7447 48.8283C12.5412 48.636 12.42 48.3733 12.4059 48.0945L12.001 40.351ZM10.6222 14.7132L9.63552 33.2214H11.6057L10.6222 14.7132Z" fill="white" />
                <path d="M35.7359 28.4481C35.4724 28.6431 34.2896 28.8414 32.6441 28.8414H32.5124C28.7681 28.8414 25.6763 27.0094 25.6763 21.9739V1.54422C25.6771 1.25563 25.793 0.97914 25.9984 0.775375C26.2039 0.571609 26.4821 0.457187 26.7722 0.457188H32.8787C33.1688 0.457187 33.4471 0.571609 33.6525 0.775375C33.858 0.97914 33.9738 1.25563 33.9747 1.54422V20.142C33.9747 21.1235 34.3668 21.3185 34.9582 21.3185H35.0256C35.347 21.3185 35.5495 21.2546 35.7488 21.2546V1.54422C35.7488 1.25592 35.8639 0.979427 36.0688 0.77557C36.2738 0.571713 36.5517 0.457188 36.8415 0.457188H42.948C43.2378 0.457188 43.5158 0.571713 43.7207 0.77557C43.9256 0.979427 44.0408 1.25592 44.0408 1.54422V48.0242C44.0408 48.3128 43.9257 48.5896 43.7209 48.7939C43.5161 48.9983 43.2381 49.1135 42.948 49.1144H36.8415C36.5514 49.1135 36.2735 48.9983 36.0686 48.7939C35.8638 48.5896 35.7488 48.3128 35.7488 48.0242L35.7359 28.4481Z" fill="white" />
                <path d="M49.5495 7.91291H46.8947C46.6046 7.91206 46.3267 7.79682 46.1219 7.59246C45.917 7.38809 45.802 7.11128 45.802 6.82269V1.54422C45.802 1.25592 45.9171 0.979427 46.1221 0.77557C46.327 0.571713 46.6049 0.457188 46.8947 0.457188H60.419C60.7091 0.457187 60.9874 0.571609 61.1928 0.775375C61.3983 0.97914 61.5141 1.25563 61.515 1.54422V6.82269C61.515 7.11183 61.3995 7.38913 61.194 7.59359C60.9884 7.79805 60.7097 7.91291 60.419 7.91291H57.7675V48.0242C57.7675 48.3133 57.652 48.5906 57.4465 48.7951C57.241 48.9995 56.9622 49.1144 56.6715 49.1144H50.6422C50.3521 49.1135 50.0741 48.9983 49.8693 48.7939C49.6644 48.5896 49.5495 48.3128 49.5495 48.0242V7.91291Z" fill="white" />
                <path d="M82.3606 40.1528C82.3606 46.2274 79.0084 49.5716 73.5511 49.5716H72.1724C66.3873 49.5716 63.2955 46.1698 63.2955 40.1528V9.61378C63.2955 3.59677 65.9277 0 72.1724 0H73.4869C79.7316 0 82.3606 3.7918 82.3606 9.61378V40.1528ZM73.8147 8.69621C73.8147 7.71469 73.4933 7.2575 72.828 7.2575C72.2366 7.2575 71.9088 7.71469 71.9088 8.69621V40.8722C71.9088 41.7226 72.2302 42.183 72.828 42.183C73.4708 42.183 73.8147 41.7226 73.8147 40.8722V8.69621Z" fill="white" />
                <path d="M89.5052 0.457186H104.145C104.435 0.457185 104.713 0.571607 104.918 0.775373C105.124 0.979138 105.24 1.25562 105.241 1.54422V6.90261C105.24 7.1912 105.124 7.46768 104.918 7.67145C104.713 7.87522 104.435 7.98964 104.145 7.98964H96.7526V18.5722H100.696C103.91 18.5722 106.481 20.6663 106.481 25.0464V42.7105C106.481 47.356 103.981 49.1048 100.564 49.1048H89.4955C89.2048 49.1048 88.9261 48.9899 88.7205 48.7855C88.515 48.581 88.3995 48.3037 88.3995 48.0146V1.54422C88.4 1.4005 88.429 1.25829 88.4848 1.12576C88.5407 0.993226 88.6223 0.872992 88.7251 0.771968C88.8278 0.670944 88.9497 0.591127 89.0835 0.537105C89.2174 0.483083 89.3607 0.455921 89.5052 0.457186ZM97.3504 42.8352C98.141 42.8352 98.3371 42.3109 98.3371 41.5915V26.4851C98.3371 25.6346 98.0157 25.1774 97.3504 25.1774H96.7751V42.8352H97.3504Z" fill="white" />
                <path d="M120.353 42.2469C119.629 47.8068 118.251 49.1144 113.925 49.1144H110.219C110.075 49.1144 109.933 49.0862 109.8 49.0314C109.667 48.9766 109.546 48.8963 109.444 48.7951C109.342 48.6938 109.262 48.5737 109.206 48.4414C109.151 48.3091 109.123 48.1673 109.123 48.0242V43.075C109.123 42.9318 109.151 42.7901 109.206 42.6578C109.262 42.5255 109.342 42.4053 109.444 42.3041C109.546 42.2029 109.667 42.1226 109.8 42.0678C109.933 42.013 110.075 41.9848 110.219 41.9848H110.309C111.82 41.9848 112.016 41.7226 112.016 40.8722V40.7443C112.016 40.4246 111.951 39.9578 111.694 37.3425L107.056 1.68489C107.036 1.5316 107.049 1.3758 107.095 1.2279C107.14 1.08 107.216 0.943391 107.319 0.827199C107.421 0.711007 107.547 0.617904 107.689 0.554099C107.831 0.490294 107.984 0.457253 108.14 0.457189H114.397C114.678 0.456879 114.949 0.564355 115.152 0.757276C115.356 0.950197 115.477 1.21372 115.49 1.49306L116.624 25.3085L117.634 1.49946C117.646 1.21926 117.766 0.954589 117.97 0.760477C118.173 0.566364 118.444 0.45774 118.726 0.457189H124.457C124.612 0.457371 124.765 0.490348 124.907 0.553933C125.048 0.617518 125.175 0.710264 125.277 0.826028C125.38 0.941792 125.457 1.07793 125.503 1.22545C125.548 1.37296 125.562 1.52847 125.543 1.68169L120.353 42.2469Z" fill="white" />
                <path d="M162.507 1.54422V6.82269C162.507 7.11183 162.391 7.38913 162.186 7.59359C161.98 7.79805 161.701 7.91291 161.411 7.91291H156.59V19.7423H160.491C160.635 19.7423 160.778 19.7705 160.911 19.8253C161.044 19.8801 161.165 19.9604 161.266 20.0616C161.368 20.1629 161.449 20.283 161.504 20.4153C161.559 20.5476 161.587 20.6894 161.587 20.8325V26.3732C161.587 26.6618 161.471 26.9382 161.265 27.142C161.06 27.3458 160.782 27.4602 160.491 27.4602H156.59V41.5276H161.61C161.9 41.5276 162.178 41.6421 162.383 41.846C162.588 42.0498 162.703 42.3263 162.703 42.6146V48.0242C162.703 48.3128 162.588 48.5896 162.383 48.7939C162.178 48.9983 161.9 49.1135 161.61 49.1144H149.532C149.241 49.1144 148.963 48.9995 148.757 48.7951C148.551 48.5906 148.436 48.3133 148.436 48.0242V1.54422C148.437 1.25563 148.553 0.97914 148.758 0.775375C148.964 0.571609 149.242 0.457187 149.532 0.457188H161.424C161.711 0.460546 161.986 0.576454 162.189 0.779858C162.392 0.983262 162.506 1.25783 162.507 1.54422Z" fill="white" />
                <path d="M168.035 7.91291H165.383C165.239 7.91291 165.097 7.88471 164.964 7.82992C164.831 7.77513 164.71 7.69483 164.608 7.59359C164.506 7.49235 164.426 7.37217 164.371 7.23989C164.315 7.10762 164.287 6.96586 164.287 6.82269V1.54422C164.287 1.4012 164.315 1.25958 164.371 1.12749C164.426 0.995398 164.507 0.875419 164.608 0.774438C164.71 0.673456 164.831 0.593454 164.964 0.539014C165.097 0.484574 165.239 0.456767 165.383 0.457188H178.904C179.194 0.457187 179.473 0.571609 179.678 0.775374C179.883 0.97914 179.999 1.25563 180 1.54422V6.82269C180 7.11183 179.885 7.38913 179.679 7.59359C179.474 7.79805 179.195 7.91291 178.904 7.91291H176.253V48.0242C176.253 48.3133 176.137 48.5906 175.932 48.7951C175.726 48.9995 175.447 49.1144 175.157 49.1144H169.131C168.987 49.1144 168.844 49.0862 168.711 49.0314C168.578 48.9766 168.457 48.8963 168.356 48.7951C168.254 48.6938 168.173 48.5737 168.118 48.4414C168.063 48.3091 168.035 48.1673 168.035 48.0242V7.91291Z" fill="white" />
                <path d="M33.634 91.2335H30.8057L30.4008 98.9801C30.3852 99.258 30.2633 99.5195 30.0601 99.7109C29.8569 99.9024 29.5878 100.009 29.308 100.01H24.3682C24.2171 100.01 24.0675 99.9786 23.9291 99.9182C23.7907 99.8579 23.6664 99.7696 23.564 99.6591C23.4616 99.5485 23.3834 99.418 23.3343 99.2758C23.2852 99.1336 23.2662 98.9829 23.2786 98.833L27.1739 52.3531C27.1958 52.0806 27.3201 51.8262 27.522 51.6408C27.7239 51.4553 27.9886 51.3523 28.2635 51.3524H36.774C37.0473 51.3521 37.3108 51.4538 37.5125 51.6372C37.7142 51.8207 37.8394 52.0727 37.8635 52.3435L42.0738 98.8235C42.0872 98.9742 42.0689 99.1261 42.02 99.2694C41.9712 99.4127 41.8929 99.5444 41.7901 99.656C41.6873 99.7675 41.5623 99.8566 41.423 99.9175C41.2837 99.9784 41.1332 100.01 40.9811 100.01H35.1317C34.8518 100.009 34.5828 99.9024 34.3796 99.7109C34.1764 99.5195 34.0546 99.258 34.039 98.9801L33.634 91.2335ZM32.252 65.5988L31.2653 84.107H33.2387L32.252 65.5988Z" fill="white" />
                <path d="M54.9682 58.8625H53.2615C53.2615 89.0114 52.7409 92.9791 52.2074 95.0285C51.5903 97.4007 49.1188 99.9968 45.1753 99.9968H44.9535C44.6634 99.9968 44.3851 99.8824 44.1797 99.6786C43.9742 99.4748 43.8584 99.1984 43.8575 98.9098V92.3461H44.1211C44.8442 92.3461 45.2396 91.9528 45.3713 91.1024C45.6317 89.0338 45.7634 85.875 45.7666 52.417C45.7666 52.1284 45.8817 51.8516 46.0865 51.6473C46.2913 51.4429 46.5692 51.3277 46.8594 51.3268H62.1577C62.4484 51.3268 62.7272 51.4417 62.9327 51.6461C63.1382 51.8506 63.2537 52.1279 63.2537 52.417V98.9098C63.2528 99.1984 63.137 99.4748 62.9316 99.6786C62.7261 99.8824 62.4479 99.9968 62.1577 99.9968H56.0513C55.7611 99.9968 55.4829 99.8824 55.2774 99.6786C55.072 99.4748 54.9561 99.1984 54.9553 98.9098L54.9682 58.8625Z" fill="white" />
                <path d="M77.7871 69.2467C81.0011 69.2467 83.5722 71.3409 83.5722 75.721V93.577C83.5722 98.2192 81.075 99.9712 77.6554 99.9712H66.5865C66.2967 99.9712 66.0188 99.8567 65.8139 99.6528C65.6089 99.449 65.4938 99.1725 65.4938 98.8842V52.4298C65.4938 52.1412 65.6088 51.8644 65.8137 51.66C66.0185 51.4557 66.2964 51.3404 66.5865 51.3396H72.7477C73.0383 51.3396 73.3171 51.4545 73.5226 51.6589C73.7282 51.8634 73.8436 52.1407 73.8436 52.4298V69.2467H77.7871ZM74.435 93.5098C75.2224 93.5098 75.4185 92.9887 75.4185 92.2693V77.4346C75.4185 76.5842 75.0971 76.127 74.435 76.127H73.8436V93.5226L74.435 93.5098Z" fill="white" />
                <path d="M86.4487 51.3428H92.5552C92.8458 51.3428 93.1246 51.4577 93.3301 51.6621C93.5357 51.8666 93.6512 52.1439 93.6512 52.433V92.41H95.1617V52.4458C95.1617 52.1567 95.2772 51.8794 95.4827 51.6749C95.6882 51.4704 95.967 51.3556 96.2577 51.3556H102.364C102.508 51.3556 102.651 51.3838 102.784 51.4386C102.917 51.4934 103.037 51.5737 103.139 51.6749C103.241 51.7761 103.322 51.8963 103.377 52.0286C103.432 52.1609 103.46 52.3026 103.46 52.4458V92.41H104.971V52.4458C104.971 52.1567 105.086 51.8794 105.292 51.6749C105.497 51.4704 105.776 51.3556 106.067 51.3556H112.202C112.492 51.3564 112.77 51.4717 112.975 51.676C113.18 51.8804 113.295 52.1572 113.295 52.4458V98.9098C113.295 99.1981 113.18 99.4746 112.975 99.6784C112.77 99.8823 112.492 99.9968 112.202 99.9968H86.4487C86.1589 99.9968 85.8809 99.8823 85.676 99.6784C85.4711 99.4746 85.3559 99.1981 85.3559 98.9098V52.4298C85.3568 52.1418 85.4722 51.8658 85.6769 51.6621C85.8817 51.4584 86.1591 51.3436 86.4487 51.3428Z" fill="white" />
                <path d="M129.602 52.4298V57.7083C129.602 57.9966 129.487 58.2731 129.282 58.4769C129.077 58.6808 128.799 58.7953 128.51 58.7953H123.689V70.6247H127.594C127.883 70.6247 128.161 70.7393 128.366 70.9431C128.571 71.147 128.686 71.4234 128.686 71.7117V77.2524C128.686 77.541 128.571 77.8178 128.366 78.0221C128.162 78.2265 127.884 78.3418 127.594 78.3426H123.689V92.41H128.709C128.999 92.41 129.278 92.5249 129.484 92.7293C129.689 92.9338 129.805 93.2111 129.805 93.5002V98.9098C129.804 99.1984 129.688 99.4748 129.483 99.6786C129.277 99.8824 128.999 99.9968 128.709 99.9968H116.631C116.341 99.9968 116.063 99.8823 115.858 99.6784C115.653 99.4746 115.538 99.1981 115.538 98.9098V52.4298C115.538 52.1412 115.653 51.8644 115.858 51.66C116.063 51.4557 116.341 51.3404 116.631 51.3396H128.522C128.81 51.3438 129.085 51.4605 129.287 51.6645C129.489 51.8685 129.602 52.1434 129.602 52.4298Z" fill="white" />
                <path d="M137.271 90.5141C136.981 90.5141 136.702 90.3997 136.497 90.1959C136.291 89.9921 136.176 89.7157 136.175 89.4271V82.0129C136.175 79.1994 136.438 78.09 137.621 75.7337L139.79 71.3537C140.514 69.9789 140.514 69.1956 140.514 67.3636V58.0248C140.515 57.9106 140.494 57.7971 140.45 57.6914C140.407 57.5856 140.343 57.4897 140.261 57.4093C140.179 57.329 140.082 57.2659 139.975 57.2239C139.868 57.1819 139.754 57.1617 139.639 57.1648C138.997 57.1936 138.997 57.5836 138.997 58.2742V68.9558C138.997 69.099 138.968 69.2408 138.913 69.373C138.858 69.5053 138.777 69.6255 138.676 69.7267C138.574 69.8279 138.453 69.9083 138.32 69.963C138.187 70.0178 138.045 70.046 137.901 70.046H132.662C132.518 70.046 132.376 70.0178 132.243 69.963C132.11 69.9083 131.989 69.8279 131.887 69.7267C131.785 69.6255 131.704 69.5053 131.649 69.373C131.594 69.2408 131.566 69.099 131.566 68.9558V58.2742C131.566 54.4152 133.08 51.0135 139.128 51.0135H140.706C146.424 51.0135 148.529 53.958 148.529 58.1431V66.6443C148.529 69.9789 148.208 71.5487 146.556 74.2311L143.663 78.9405C142.545 80.7692 142.284 81.7507 142.284 83.8449V89.4271C142.284 89.7157 142.168 89.9921 141.962 90.1959C141.757 90.3997 141.479 90.5141 141.189 90.5141H137.271ZM137.271 99.9968C136.981 99.9968 136.702 99.8824 136.497 99.6786C136.291 99.4748 136.176 99.1984 136.175 98.9098V92.6498C136.176 92.3612 136.291 92.0847 136.497 91.8809C136.702 91.6772 136.981 91.5628 137.271 91.5628H141.195C141.485 91.5628 141.763 91.6772 141.969 91.8809C142.174 92.0847 142.29 92.3612 142.291 92.6498V98.9098C142.29 99.1967 142.176 99.4718 141.972 99.6752C141.769 99.8787 141.493 99.9943 141.205 99.9968H137.271Z" fill="white" />
                <path d="M132.128 49.1048H145.563C145.853 49.1048 146.132 48.9899 146.338 48.7855C146.543 48.581 146.659 48.3037 146.659 48.0146V42.6146C146.658 42.326 146.542 42.0495 146.337 41.8458C146.131 41.642 145.853 41.5276 145.563 41.5276H144.949V1.54422C144.949 1.4012 144.92 1.25958 144.865 1.12749C144.81 0.995398 144.729 0.875419 144.628 0.774438C144.526 0.673456 144.405 0.593454 144.272 0.539014C144.139 0.484574 143.997 0.456767 143.853 0.457188H128.426C128.136 0.457188 127.858 0.571713 127.653 0.77557C127.448 0.979427 127.333 1.25592 127.333 1.54422C127.266 18.5977 127.134 28.4833 126.941 33.3525C126.62 38.6502 126.414 40.351 126.086 41.5276H125.996C125.706 41.5276 125.428 41.6421 125.224 41.846C125.019 42.0498 124.904 42.3263 124.904 42.6146V48.0242C124.904 48.3128 125.019 48.5896 125.223 48.7939C125.428 48.9983 125.706 49.1135 125.996 49.1144L132.128 49.1048ZM134.378 33.7362C134.574 29.42 134.77 21.635 134.77 7.96726H136.808V41.7226H133.523C133.835 40.0889 134.099 38.193 134.378 33.7586V33.7362Z" fill="white" />
                <path d="M7.23148 99.9968H20.6657C20.9558 99.9968 21.2341 99.8824 21.4396 99.6786C21.645 99.4748 21.7608 99.1984 21.7617 98.9098V93.5002C21.7617 93.357 21.7334 93.2153 21.6783 93.083C21.6232 92.9507 21.5425 92.8306 21.4407 92.7293C21.3389 92.6281 21.2181 92.5478 21.0852 92.493C20.9522 92.4382 20.8097 92.41 20.6657 92.41H20.0519V52.4458C20.0519 52.1572 19.9369 51.8804 19.732 51.676C19.5272 51.4717 19.2493 51.3564 18.9592 51.3556H3.53223C3.24268 51.3564 2.96523 51.4712 2.76049 51.6749C2.55574 51.8786 2.44033 52.1546 2.43948 52.4426C2.37199 69.4993 2.24023 79.3849 2.04739 84.2541C1.726 89.5486 1.52351 91.2494 1.19247 92.426H1.10569C0.961769 92.426 0.819261 92.4542 0.686294 92.509C0.553327 92.5638 0.432536 92.6441 0.330767 92.7453C0.228999 92.8465 0.148251 92.9667 0.0931743 93.099C0.0380975 93.2313 0.00976562 93.373 0.00976562 93.5162V98.9258C0.0106164 99.2144 0.126427 99.4908 0.331866 99.6946C0.537305 99.8984 0.815582 100.013 1.10569 100.013L7.23148 99.9968ZM9.48124 84.6282C9.67729 80.312 9.87333 72.5302 9.87333 58.8625H11.9142V92.6082H8.62632C8.94129 90.9873 9.20483 89.069 9.46837 84.6282H9.48124Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_2201_3814">
                  <rect width="180" height="100" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
          <div className="footer__items">
            {footerStore.data.length > 0 &&
              footerStore.data.map(({ name, element }, index) => (
                <div className="footer__item" key={index}>
                  <p className="footer__item_title p2">
                    {name}
                  </p>
                  <div className="footer__item_links">
                    {element.map(({ url, title }, index) => (
                      <Link className='footer__item_link p3' to={url} target='_blank' key={index}>
                        {title}
                      </Link>
                    ))}
                  </div>
                </div>
              ))
            }
          </div>
          <Link className="footer__logo __right" to="https://5pub.ru/" target='__blank'>
            <svg width="169" height="24" viewBox="0 0 169 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1901_3034)">
                <path d="M0.477539 20.1274H2.78802C4.80771 20.1274 5.24863 20.9962 5.24863 21.781V21.9221C5.24863 22.6751 4.83141 23.6248 2.79276 23.6248H0.477539V20.1274ZM1.56956 22.783H2.70426C3.94009 22.783 4.10445 22.2376 4.10445 21.8809V21.8523C4.10445 21.4956 3.96064 20.955 2.70426 20.955H1.56956V22.783Z" fill="white" />
                <path d="M7.02269 23.6253H5.93066V20.1279H7.02269V23.6253Z" fill="white" />
                <path d="M10.0337 23.6889C8.14516 23.6889 7.70898 22.6314 7.70898 21.9291V21.7991C7.70898 21.1158 8.06456 20.0679 10.0795 20.0679H10.2818C12.2304 20.0679 12.5717 20.9192 12.5907 21.3932H11.4291C11.4007 21.3124 11.2711 20.8939 10.187 20.8939C9.10286 20.8939 8.86106 21.3552 8.86106 21.8451V21.8974C8.86106 22.354 9.21664 22.8375 10.1775 22.8375C11.2142 22.8375 11.5271 22.438 11.5271 22.3397V22.3286H10.1506V21.6675H12.6065V23.6255H11.9507C11.9239 23.439 11.8767 23.2562 11.81 23.0801C11.652 23.2545 11.211 23.6873 10.1332 23.6873L10.0337 23.6889Z" fill="white" />
                <path d="M14.5129 23.6248H13.4209V20.1274H14.5129V23.6248Z" fill="white" />
                <path d="M16.6287 20.955H15.1289V20.1274H19.2378V20.955H17.7333V23.6248H16.6271L16.6287 20.955Z" fill="white" />
                <path d="M22.6144 22.9859H20.5014L20.1853 23.62H19.0791L20.8349 20.1274H22.2572L24.122 23.6248H22.9462L22.6144 22.9859ZM21.5366 20.8853L20.8902 22.1964H22.2114L21.5366 20.8853Z" fill="white" />
                <path d="M25.7221 20.1279V22.7565H28.1448V23.6253H24.627V20.1279H25.7221Z" fill="white" />
                <path d="M34.9736 23.6775C34.57 23.6927 34.1699 23.5967 33.8168 23.4001C33.348 23.602 32.8413 23.6999 32.3312 23.687C30.5691 23.687 30.1709 23.1559 30.1709 22.6296V22.5788C30.1709 22.1127 30.5407 21.8401 31.2487 21.7323C31.1145 21.6463 31.0026 21.5296 30.9221 21.3918C30.8416 21.254 30.7949 21.099 30.7857 20.9396V20.9015C30.7857 20.502 30.9864 20.0454 32.2601 20.0454H32.5082C33.7836 20.0454 33.9527 20.5448 33.9527 20.8793V20.9015C33.9527 21.2677 33.6951 21.5975 32.8148 21.7624L33.763 22.406C33.922 22.1308 34.0034 21.8173 33.9985 21.4992H35.0242C35.0107 21.9828 34.8391 22.4485 34.5358 22.8246C34.7733 22.8848 35.018 22.9115 35.2628 22.9039L34.9736 23.6775ZM33.0661 22.9197L31.6612 22.0034C31.4589 22.0779 31.2866 22.2095 31.2866 22.4488V22.4647C31.2866 22.7263 31.5585 23.0053 32.2981 23.0053C32.5567 23.0103 32.8149 22.9815 33.0661 22.9197ZM32.3597 21.4564C32.7295 21.3454 32.9918 21.2091 32.9918 20.9808C32.9918 20.7525 32.8749 20.6383 32.3597 20.6383C31.9204 20.6383 31.8224 20.7557 31.8224 20.9063C31.8224 21.0569 32.0073 21.2186 32.3597 21.4564Z" fill="white" />
                <path d="M39.1764 23.6889C37.3479 23.6889 37.1377 22.8962 37.1377 22.438V22.3857H38.2755C38.2898 22.5966 38.4115 22.9406 39.3123 22.9406H39.3771C40.2952 22.9406 40.3995 22.7583 40.3995 22.5696C40.3995 22.3809 40.2905 22.2398 39.4845 22.2176L38.8856 22.2034C37.5818 22.1653 37.1835 21.7278 37.1835 21.1776V21.1348C37.1835 20.6148 37.4996 20.0679 39.08 20.0679H39.4039C41.0538 20.0679 41.4284 20.6465 41.4284 21.1776V21.2252H40.2937C40.2715 21.0936 40.2004 20.8067 39.287 20.8067H39.2111C38.3688 20.8067 38.3072 20.9573 38.3072 21.1079C38.3072 21.2585 38.402 21.3932 39.1131 21.4075L39.6994 21.4186C41.0111 21.4408 41.5406 21.7611 41.5406 22.4555V22.522C41.5406 23.1007 41.2498 23.6936 39.4987 23.6936L39.1764 23.6889Z" fill="white" />
                <path d="M44.7187 20.0659C46.9107 20.0659 47.1683 21.1757 47.1683 21.7972V21.9446C47.1683 22.5613 46.9154 23.6885 44.7187 23.6885H44.5148C42.3023 23.6885 42.0605 22.555 42.0605 21.9446V21.8051C42.0605 21.1789 42.3087 20.0738 44.5148 20.0738L44.7187 20.0659ZM46.0257 21.8289C46.0257 21.4293 45.7949 20.9125 44.6144 20.9125C43.4339 20.9125 43.2031 21.4293 43.2031 21.8289V21.8859C43.2031 22.2855 43.4339 22.8372 44.6144 22.8372C45.7949 22.8372 46.0257 22.3013 46.0257 21.897V21.8289Z" fill="white" />
                <path d="M50.0471 23.6889C48.0258 23.6889 47.7129 22.5553 47.7129 21.9354V21.7991C47.7129 21.165 47.9847 20.0679 50.0471 20.0679H50.2399C52.258 20.0679 52.5725 21.0365 52.5725 21.4456V21.4979H51.4157C51.3872 21.4028 51.2466 20.9145 50.1403 20.9145C49.0941 20.9145 48.8555 21.3901 48.8555 21.8229V21.8784C48.8555 22.2922 49.1321 22.8375 50.1498 22.8375C51.2798 22.8375 51.392 22.2969 51.4141 22.2176H52.5709V22.262C52.5709 22.7044 52.2232 23.6889 50.2336 23.6889H50.0471Z" fill="white" />
                <path d="M54.3567 23.6248H53.2646V20.1274H54.3567V23.6248Z" fill="white" />
                <path d="M58.3966 22.9862H56.2836L55.9676 23.6204H54.8613L56.6234 20.123H58.0457L59.9106 23.6204H58.7348L58.3966 22.9862ZM57.3188 20.8856L56.6724 22.1967H57.9936L57.3188 20.8856Z" fill="white" />
                <path d="M61.5041 20.1274V22.756H63.9268V23.6248H60.4121V20.1274H61.5041Z" fill="white" />
                <path d="M67.2366 20.1279V22.7565H69.6671V23.6253H66.1445V20.1279H67.2366Z" fill="white" />
                <path d="M72.7929 20.0659C74.9849 20.0659 75.2425 21.1757 75.2425 21.7972V21.9446C75.2425 22.5613 74.9896 23.6885 72.7929 23.6885H72.5891C70.3766 23.6885 70.1348 22.555 70.1348 21.9446V21.8051C70.1348 21.1789 70.3829 20.0738 72.5891 20.0738L72.7929 20.0659ZM74.0999 21.8289C74.0999 21.4293 73.8691 20.9125 72.6886 20.9125C71.5081 20.9125 71.2774 21.4293 71.2774 21.8289V21.8859C71.2774 22.2855 71.5081 22.8372 72.6886 22.8372C73.8691 22.8372 74.0999 22.3013 74.0999 21.897V21.8289Z" fill="white" />
                <path d="M78.1118 23.6889C76.2154 23.6889 75.7871 22.6314 75.7871 21.9291V21.7991C75.7871 21.1158 76.1427 20.0679 78.1497 20.0679H78.3583C80.3069 20.0679 80.6483 20.9192 80.6672 21.3932H79.5057C79.4772 21.3124 79.3477 20.8939 78.2635 20.8939C77.1794 20.8939 76.9376 21.3552 76.9376 21.8451V21.8974C76.9376 22.354 77.2932 22.8375 78.2541 22.8375C79.2908 22.8375 79.6037 22.438 79.6037 22.3397V22.3286H78.2351V21.6675H80.691V23.6255H80.0351C80.0083 23.439 79.9611 23.2562 79.8944 23.0801C79.7443 23.2545 79.2955 23.6873 78.2177 23.6873L78.1118 23.6889Z" fill="white" />
                <path d="M82.5911 23.6248H81.499V20.1274H82.5911V23.6248Z" fill="white" />
                <path d="M85.3121 23.6889C83.4836 23.6889 83.2734 22.8962 83.2734 22.438V22.3857H84.4113C84.4255 22.5966 84.5472 22.9406 85.448 22.9406H85.5128C86.431 22.9406 86.5353 22.7583 86.5353 22.5696C86.5353 22.3809 86.4263 22.2398 85.6203 22.2176L85.0213 22.2034C83.7175 22.1653 83.3193 21.7278 83.3193 21.1776V21.1348C83.3193 20.6148 83.6353 20.0679 85.2157 20.0679H85.5318C87.1817 20.0679 87.5562 20.6465 87.5562 21.1776V21.2252H86.4183C86.3962 21.0936 86.3251 20.8067 85.4117 20.8067H85.3358C84.4935 20.8067 84.4318 20.9573 84.4318 21.1079C84.4318 21.2585 84.5267 21.3932 85.2378 21.4075L85.8241 21.4186C87.1358 21.4408 87.6652 21.7611 87.6652 22.4555V22.522C87.6652 23.1007 87.3745 23.6936 85.6234 23.6936L85.3121 23.6889Z" fill="white" />
                <path d="M89.6501 20.955H88.1582V20.1274H92.2671V20.955H90.7626V23.6248H89.6564L89.6501 20.955Z" fill="white" />
                <path d="M93.9696 23.6248H92.876V20.1274H93.9696V23.6248Z" fill="white" />
                <path d="M96.9889 23.6889C94.9692 23.6889 94.6562 22.5553 94.6562 21.9354V21.7991C94.6562 21.165 94.9281 20.0679 96.9889 20.0679H97.1817C99.2013 20.0679 99.5158 21.0365 99.5158 21.4456V21.4979H98.3574C98.329 21.4028 98.1899 20.9145 97.0837 20.9145C96.0375 20.9145 95.7988 21.3901 95.7988 21.8229V21.8784C95.7988 22.2922 96.0754 22.8375 97.0932 22.8375C98.2215 22.8375 98.3337 22.2969 98.3574 22.2176H99.5158V22.262C99.5158 22.7044 99.1682 23.6889 97.1769 23.6889H96.9889Z" fill="white" />
                <path d="M22.0765 14.7955H16.8613V1.04395H22.0765V14.7955Z" fill="white" />
                <path d="M29.6556 14.7965L22.7305 1.04492H28.6631L33.2699 10.6381L37.5984 1.04492H43.0349L36.6044 14.7965H29.6556Z" fill="white" />
                <path d="M58.8823 1.04395V4.40812H48.877V6.12668H58.6752V9.47342H48.877V11.3584H59.0292V14.7955H43.7188V1.04395H58.8823Z" fill="white" />
                <path d="M15.4158 4.40812V1.04395H5.72351L0.547852 6.23765V14.7955H5.72351V9.50988H15.2135V6.23765H5.72351V4.40812H15.4158Z" fill="white" />
                <path d="M5.72351 1.04395V6.23765H0.547852L5.72351 1.04395Z" fill="#876EFF" />
                <path d="M85.3535 1.04492H90.605V7.20096C90.605 8.88304 91.3431 10.8981 94.8056 10.8981C98.2144 10.8981 98.9888 8.88304 98.9888 7.20096V1.04492H104.221V7.60681C104.221 10.2687 102.957 15.0185 95.1928 15.0185H94.4564C86.4787 15.0185 85.3535 10.2623 85.3535 7.60681V1.04492Z" fill="white" />
                <path d="M105.937 1.04395H118.834C122.832 1.04395 123.9 2.59762 123.9 4.16873V4.37166C123.9 6.09021 122.445 6.86705 121.543 7.06681C122.758 7.36327 124.694 8.17657 124.694 10.5974V10.8194C124.694 12.9819 123.385 14.7924 118.908 14.7924H105.937V1.04395ZM111.169 4.16873V6.10924H117.249C118.336 6.10924 118.649 5.66533 118.649 5.1485V5.11045C118.649 4.57459 118.333 4.16873 117.249 4.16873H111.169ZM111.169 9.19597V11.5614H117.581C118.944 11.5614 119.202 10.9145 119.202 10.3787V10.3232C119.202 9.73183 118.927 9.19597 117.581 9.19597H111.169Z" fill="white" />
                <path d="M131.185 1.04492V10.86H140.691V14.7965H125.952V1.04492H131.185Z" fill="white" />
                <path d="M147.165 14.7965H141.95V1.04492H147.165V14.7965Z" fill="white" />
                <path d="M155.797 4.88552L155.827 4.87284C156.485 4.61918 157.346 4.46381 158.478 4.46223H158.506C162.511 4.46223 162.931 6.20615 163.048 6.58188H168.518V6.3409C168.518 4.55576 167.293 0.741338 158.914 0.741338H158.19C157.391 0.738538 156.592 0.781944 155.797 0.871339C154.996 0.957662 154.203 1.11254 153.427 1.33427C149.138 2.58355 148.435 5.56407 148.435 7.60604V8.15141C148.435 10.5041 149.465 14.2329 155.794 14.9765C156.589 15.0663 157.388 15.1102 158.187 15.1081H158.911C160.125 15.1152 161.338 15.0091 162.533 14.791C167.603 13.8398 168.515 10.8925 168.515 9.39276V9.09471H163.067C163.004 9.36264 162.693 10.8022 160.132 11.2366C159.601 11.3252 159.062 11.3681 158.524 11.365H158.484C157.343 11.365 156.469 11.1684 155.797 10.8624C154.332 10.1807 153.901 8.9441 153.901 7.91994V7.6964C153.901 6.62627 154.293 5.4848 155.797 4.88552Z" fill="white" />
                <path d="M77.4929 1.04395H66.1396V14.7955H71.339V10.4326H77.4929C82.4742 10.4326 83.9566 8.33828 83.9566 5.85875V5.56387C83.9566 3.04787 82.5295 1.04395 77.4929 1.04395ZM78.5992 5.76521C78.5992 6.51985 78.0682 7.01607 76.8782 7.01607H71.3469V4.46203H76.8782C78.1045 4.46203 78.5992 4.92179 78.5992 5.71131V5.76521Z" fill="white" />
                <path d="M102.267 23.6889C100.439 23.6889 100.229 22.8962 100.229 22.438V22.3857H101.368C101.381 22.5966 101.502 22.9406 102.403 22.9406H102.468C103.386 22.9406 103.49 22.7583 103.49 22.5696C103.49 22.3809 103.381 22.2398 102.575 22.2176L101.976 22.2034C100.674 22.1653 100.274 21.7278 100.274 21.1776V21.1348C100.274 20.6148 100.59 20.0679 102.171 20.0679H102.487C104.137 20.0679 104.511 20.6465 104.511 21.1776V21.2252H103.377C103.354 21.0936 103.285 20.8067 102.37 20.8067H102.296C101.452 20.8067 101.39 20.9573 101.39 21.1079C101.39 21.2585 101.485 21.3932 102.196 21.4075L102.782 21.4186C104.094 21.4408 104.623 21.7611 104.623 22.4555V22.522C104.623 23.1007 104.333 23.6936 102.582 23.6936L102.267 23.6889Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_1901_3034">
                  <rect width="169" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        </div>
      </div>
    </footer>
  )
})
